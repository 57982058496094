import { Container, Col, Row, Button, Card } from "react-bootstrap";

import classes from "./ServiceCard.module.css";

const ServiceCard = (props) => {
  const { title, body, buttonText, buttonPath, icon } = props;

  console.log(icon);

  return (
    <Card className={`${classes.container} shadow`}>
      <Card.Body className="d-flex flex-column justify-content-between">
        <div>
          <div className="mt-3 mb-3">{icon}</div>
          <Card.Title>{title}</Card.Title>
          <div className={`${classes.horizontalLine}`}></div>
          <Card.Text className="text-muted">{body}</Card.Text>
        </div>
        <Button variant="primary" href={buttonPath}>
          {buttonText}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ServiceCard;
