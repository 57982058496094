import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";

import classes from "./Legal.module.css";

const Privacy = (props) => {
  return (
    <Container className={classes.legal}>
      <Row>
        <Col>
          <div className={classes.header}>
            <h2>Privacy and Cookies Policy</h2>
            <p>Last updated: June 12th, 2024</p>
          </div>

          <ol>
            <li className={classes["list-header"]}>
              <h3>Introduction</h3>
              <ol>
                <li>
                  We are committed to safeguarding the privacy of our website
                  users; in this policy we explain how we will handle your
                  personal data.
                </li>
                <li>
                  By using our website and agreeing to this policy, you consent
                  to our use of cookies in accordance with the terms of this
                  policy.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>How we use your personal data</h3>
              <ol>
                <li>
                  We may process data about your use of our website and services
                  ("usage data"). The usage data may include your IP address,
                  geographical location, browser type and version, operating
                  system, referral source, length of visit, page views and
                  website navigation paths, as well as information about the
                  timing, frequency and pattern of your service use. The source
                  of the usage data is our analytics tracking system. This usage
                  data may be processed for the purposes of analysing the use of
                  the website and services. The legal basis for this processing
                  isour legitimate interests, namely monitoring and improving
                  our website and services.
                </li>
                <li>
                  We may process information contained in any enquiry you submit
                  to us regarding products and/or services ("enquiry data"). The
                  enquiry data may be processed for the purposes of offering,
                  marketing and selling relevant products and/or services to
                  you. The legal basis for this processing is consent.
                </li>
                <li>
                  We may process your personal data that are provided in the
                  course of the use of our services ("service data"). The source
                  of the service data is you or your employer. The service data
                  may be processed for the purposes of providing our services,
                  ensuring the security of our website and services, maintaining
                  back-ups of our databases and communicating with you. The
                  legal basis for this processing is consent.
                </li>
                <li>
                  We may process information relating to transactions, including
                  purchases of goods and services, that you enter into with us
                  and/or through our website ("transaction data"). The
                  transaction data may include your contact details and, the
                  transaction details. The transaction data may be processed for
                  the purpose of supplying the purchased goods and services and
                  keeping proper records of those transactions. The legal basis
                  for this processing is the performance of a contract between
                  you and us and/or taking steps, at your request, to enter into
                  such a contract and our legitimate interests, namely our
                  interest in the proper administration of our website and
                  business.
                </li>
                <li>
                  We may process information contained in or relating to any
                  communication that you send to us ("correspondence data"). The
                  correspondence data may include the communication content and
                  metadata associated with the communication. Our website will
                  generate the metadata associated with communications made
                  using the website contact forms. The correspondence data may
                  be processed for the purposes of communicating with you and
                  record-keeping. The legal basis for this processing is our
                  legitimate interests, namely the proper administration of our
                  website and business and communications with users.
                </li>
                <li>
                  In addition to the specific purposes for which we may process
                  your personal data set out in this Section 2, we may also
                  process any of your personal data where such processing is
                  necessary for compliance with a legal obligation to which we
                  are subject, or in order to protect your vital interests or
                  the vital interests of another natural person.
                </li>
                <li>
                  Please do not supply any other person's personal data to us,
                  unless we prompt you to do so.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Providing your personal data to others</h3>
              <ol>
                <li>
                  We may disclose your personal data to any member of our group
                  of companies (this means our subsidiaries, our ultimate
                  holding company and all its subsidiaries) insofar as
                  reasonably necessary for the purposes set out in this policy.
                </li>
                <li>
                  We may disclose your personal data to our insurers and/or
                  professional advisers insofar as reasonably necessary for the
                  purposes of obtaining and maintaining insurance coverage,
                  managing risks, obtaining professional advice and managing
                  legal disputes.
                </li>
                <li>
                  We may disclose your personal data to our suppliers or
                  subcontractors insofar as reasonably necessary for procuring
                  goods and services on your behalf.
                </li>
                <li>
                  Financial transactions relating to our website and services
                  are handled by our payment services providers, JCC Payment
                  Systems Ltd. We will share transaction data with our payment
                  services providers only to the extent necessary for the
                  purposes of processing your payments, refunding such payments
                  and dealing with complaints and queries relating to such
                  payments and refunds. You can find information about the
                  payment services providers' privacy policies and practices at
                  http://www.jcc.com.cy/ and https://www.jccsmart.com/.
                </li>
                <li>
                  We may disclose your enquiry data to one or more of those
                  selected third party suppliers of goods and services
                  identified on our website for the purpose of enabling them to
                  contact you so that they can offer, market and sell to you
                  relevant goods and/or services. Each such third party will act
                  as a data controller in relation to the enquiry data that we
                  supply to it; and upon contacting you, each such third party
                  will supply to you a copy of its own privacy policy, which
                  will govern that third party's use of your personal data.
                </li>
                <li>
                  In addition to the specific disclosures of personal data set
                  out in this Section 4, we may also disclose your personal data
                  where such disclosure is necessary for compliance with a legal
                  obligation to which we are subject, or in order to protect
                  your vital interests or the vital interests of another natural
                  person.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>International transfers of your personal data</h3>
              <ol>
                <li>
                  In this Section 5, we provide information about the
                  circumstances in which your personal data may be transferred
                  to countries outside the European Economic Area (EEA).
                </li>
                <li>
                  The hosting facilities for our website are situated in the
                  United States of America. The European Commission has made an
                  "adequacy decision" with respect to the data protection laws
                  of each of these countries. Transfers to each of these
                  countries will be protected by appropriate safeguards, namely
                  the use of standard data protection clauses adopted or
                  approved by the European Commission.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Retaining and deleting personal data</h3>
              <ol>
                <li>
                  This Section 5 sets out our data retention policies and
                  procedure, which are designed to help ensure that we comply
                  with our legal obligations in relation to the retention and
                  deletion of personal data.
                </li>
                <li>
                  Personal data that we process for any purpose or purposes
                  shall not be kept for longer than is necessary for that
                  purpose or those purposes.
                </li>
                <li>
                  Notwithstanding the other provisions of this Section 5, we may
                  retain your personal data where such retention is necessary
                  for compliance with a legal obligation to which we are
                  subject, or in order to protect your vital interests or the
                  vital interests of another natural person.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Amendments</h3>
              <ol>
                <li>
                  We may update this policy from time to time by publishing a
                  new version on our website.
                </li>
                <li>
                  You should check this page occasionally to ensure you are
                  happy with any changes to this policy.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Your rights</h3>
              <ol>
                <li>
                  In this Section 8, we have summarised the rights that you have
                  under data protection law. Some of the rights are complex, and
                  not all of the details have been included in our summaries.
                  Accordingly, you should read the relevant laws and guidance
                  from the regulatory authorities for a full explanation of
                  these rights.
                </li>
                <li>Your principal rights under data protection law are:</li>
                <ul>
                  <li>(a) the right to access;</li>
                  <li>(b) the right to rectification;</li>
                  <li>(c) the right to erasure;</li>
                  <li>(d) the right to restrict processing;</li>
                  <li>(e) the right to object to processing;</li>
                  <li>(f) the right to data portability;</li>
                  <li>
                    (g) the right to complain to a supervisory authority; and
                  </li>
                  <li>(h) the right to withdraw consent.</li>
                </ul>
                <li>
                  You have the right to confirmation as to whether or not we
                  process your personal data and, where we do, access to the
                  personal data, together with certain additional information.
                  That additional information includes details of the purposes
                  of the processing, the categories of personal data concerned
                  and the recipients of the personal data. Providing the rights
                  and freedoms of others are not affected, we will supply to you
                  a copy of your personal data. The first copy will be provided
                  free of charge, but additional copies may be subject to a
                  reasonable fee.
                </li>
                <li>
                  You have the right to have any inaccurate personal data about
                  you rectified and, taking into account the purposes of the
                  processing, to have any incomplete personal data about you
                  completed.
                </li>
                <li>
                  In some circumstances you have the right to the erasure of
                  your personal data without undue delay. Those circumstances
                  include: the personal data are no longer necessary in relation
                  to the purposes for which they were collected or otherwise
                  processed; you withdraw consent to consent-based processing;
                  the processing is for direct marketing purposes; and the
                  personal data have been unlawfully processed. However, there
                  are certain general exclusions of the right to erasure. Those
                  general exclusions include where processing is necessary: for
                  exercising the right of freedom of expression and information;
                  for compliance with a legal obligation; or for the
                  establishment, exercise or defence of legal claims.
                </li>
                <li>
                  In some circumstances you have the right to restrict the
                  processing of your personal data. Those circumstances are: you
                  contest the accuracy of the personal data; processing is
                  unlawful but you oppose erasure; we no longer need the
                  personal data for the purposes of our processing, but you
                  require personal data for the establishment, exercise or
                  defence of legal claims; and you have objected to processing,
                  pending the verification of that objection. Where processing
                  has been restricted on this basis, we may continue to store
                  your personal data. However, we will only otherwise process
                  it: with your consent; for the establishment, exercise or
                  defence of legal claims; for the protection of the rights of
                  another natural or legal person; or for reasons of important
                  public interest.
                </li>
                <li>
                  You have the right to object to our processing of your
                  personal data on grounds relating to your particular
                  situation, but only to the extent that the legal basis for the
                  processing is that the processing is necessary for: the
                  performance of a task carried out in the public interest or in
                  the exercise of any official authority vested in us; or the
                  purposes of the legitimate interests pursued by us or by a
                  third party. If you make such an objection, we will cease to
                  process the personal information unless we can demonstrate
                  compelling legitimate grounds for the processing which
                  override your interests, rights and freedoms, or the
                  processing is for the establishment, exercise or defence of
                  legal claims.
                </li>
                <li>
                  To the extent that the legal basis for our processing of your
                  personal data is consent, and such processing is carried out
                  by automated means, you have the right to receive your
                  personal data from us in a structured, commonly used and
                  machine-readable format. However, this right does not apply
                  where it would adversely affect the rights and freedoms of
                  others.
                </li>
                <li>
                  If you consider that our processing of your personal
                  information infringes data protection laws, you have a legal
                  right to lodge a complaint with a supervisory authority
                  responsible for data protection. You may do so in the EU
                  member state of your habitual residence, your place of work or
                  the place of the alleged infringement.
                </li>
                <li>
                  To the extent that the legal basis for our processing of your
                  personal information is consent, you have the right to
                  withdraw that consent at any time. Withdrawal will not affect
                  the lawfulness of processing before the withdrawal.
                </li>
                <li>
                  You may exercise any of your rights in relation to your
                  personal data by written notice to us.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Third party websites</h3>
              <ol>
                <li>
                  Our website may include hyperlinks to, and details of, third
                  party websites.
                </li>
                <li>
                  We have no control over, and are not responsible for, the
                  privacy policies and practices of third parties.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Updating information</h3>
              <ol>
                <li>
                  Please let us know if the personal information that we hold
                  about you needs to be corrected or updated.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>About cookies</h3>
              <ol>
                <li>
                  A cookie is a file containing an identifier (a string of
                  letters and numbers) that is sent by a web server to a web
                  browser and is stored by the browser. The identifier is then
                  sent back to the server each time the browser requests a page
                  from the server.
                </li>
                <li>
                  Cookies may be either "persistent" cookies or "session"
                  cookies: a persistent cookie will be stored by a web browser
                  and will remain valid until its set expiry date, unless
                  deleted by the user before the expiry date; a session cookie,
                  on the other hand, will expire at the end of the user session,
                  when the web browser is closed.
                </li>
                <li>
                  Cookies do not typically contain any information that
                  personally identifies a user, but personal information that we
                  store about you may be linked to the information stored in and
                  obtained from cookies.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Cookies that we use</h3>
              <ol>
                <li>
                  personalisation - we use cookies to store information about
                  your preferences and to personalise our website for you;
                </li>
                <li>
                  analysis - we use cookies to help us to analyse the use and
                  performance of our website and services; and
                </li>
                <li>
                  cookie consent - we use cookies to store your preferences in
                  relation to the use of cookies more generally.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Cookies used by our service providers</h3>
              <ol>
                <li>
                  Our service providers use cookies and those cookies may be
                  stored on your computer when you visit our website.
                </li>
                <li>
                  We use Google Analytics to analyse the use of our website.
                  Google Analytics gathers information about website use by
                  means of cookies. The information gathered relating to our
                  website is used to create reports about the use of our
                  website. Google's privacy policy is available at:
                  https://www.google.com/policies/privacy/.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Managing cookies</h3>
              <ol>
                <li>
                  Most browsers allow you to refuse to accept cookies and to
                  delete cookies. The methods for doing so vary from browser to
                  browser, and from version to version.
                </li>
                <li>
                  Blocking all cookies will have a negative impact upon the
                  usability of many websites.
                </li>
                <li>
                  If you block cookies, you will not be able to use all the
                  features on our website.
                </li>
              </ol>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};

export default Privacy;
