import React, { Fragment } from "react";

import classes from "./Input.module.css";

const Input = React.forwardRef((props, ref) => {
  let inputField;
  const controlClasses = [
    classes.control,
    props.hasError ? classes.invalid : "",
    props.className ? props.className : "",
  ];

  if (props.type == "text") {
    inputField = (
      <div className={controlClasses.join(" ")}>
        <label>{props.label}</label>
        <input
          name={props.name}
          id={props.id}
          type={props.type}
          placeholder={props.hasError ? props.errorText : props.placeholder}
          onClick={props.onClick}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          value={props.value}
          maxLength={props.maxLength}
          autoFocus={props.autofocus}
          readOnly={props.readOnly}
          ref={ref}
        />
      </div>
    );
  } else if (props.type == "textarea") {
    inputField = (
      <div className={controlClasses.join(" ")}>
        <label>{props.label}</label>
        <textarea
          name={props.name}
          id={props.id}
          type={props.type}
          placeholder={props.hasError ? props.errorText : props.placeholder}
          onClick={props.onClick}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          value={props.value}
          maxLength={props.maxLength}
          autoFocus={props.autofocus}
          readOnly={props.readOnly}
          ref={ref}
          rows="5"
        />
      </div>
    );
  }

  return <Fragment>{inputField}</Fragment>;
});

export default Input;
