import SecurityOverviewImage from "../assets/nav-services.jpeg";
import IndustriesOverviewImage from "../assets/nav-industries.jpeg";
import PartnershipsOverviewImage from "../assets/nav-partnerships.jpeg";
import CareersOverviewImage from "../assets/nav-careers.jpeg";
import AboutOverviewImage from "../assets/nav-about.jpeg";

// Image imports
import AdobeStock_762132435 from "../assets/AdobeStock_762132435_preview.jpeg";
import AdobeStock_815313473 from "../assets/AdobeStock_815313473_Preview.jpeg";
import AdobeStock_814768735 from "../assets/AdobeStock_814768735_Preview.jpeg";
import AdobeStock_717607430 from "../assets/AdobeStock_717607430_Preview.jpeg";
import AdobeStock_790571897 from "../assets/AdobeStock_790571897_Preview.jpeg";
import AdobeStock_899201740 from "../assets/AdobeStock_899201740_Preview.jpeg";

const NavbarData = [
  {
    id: "services",
    displayName: "Services",
    description:
      "We offer a comprehensive suite of IT consulting services designed to empower your business.",
    links: [
      { name: "Operational Support", url: "/services/operational-support" },
      { name: "Cyber Security", url: "/services/cyber-security" },
      { name: "Managed Services", url: "/services/managed-services" },
      { name: "Systems Integration", url: "/services/systems-integration" },
      { name: "Solutions Design", url: "/services/solutions-design" },
      {
        name: "Cloud Costs/SaaS Optimization",
        url: "/services/cloud-costs-saas-optimization",
      },
      { name: "Procurement", url: "/services/procurement" },
      { name: "Cost Optimization", url: "/services/cost-optimization" },
      {
        name: "Compliance Audit Preperation",
        url: "/services/compliance-audit-preparation",
      },
    ],
    image: SecurityOverviewImage,
  },
  {
    id: "industries",
    displayName: "Industries",
    description:
      "We specialize in providing top-tier IT consulting services to a diverse range of industries.",
    links: [
      { name: "Financial", url: "/industries/financial" },
      { name: "Healthcare", url: "/industries/healthcare" },
      { name: "Aquisitions", url: "/industries/aquisitions" },
      { name: "Startups", url: "/industries/startups" },
    ],
    image: IndustriesOverviewImage,
  },
  {
    id: "careers",
    displayName: "Careers",
    description:
      "We’re always on the lookout for passionate and innovative professionals to join our team.",
    links: [{ name: "" }],
    image: CareersOverviewImage,
  },
  {
    id: "partnerships",
    displayName: "Partnerships",
    description:
      "In laboris dolor consequat culpa ut. Adipisicing cupidatat veniam aliqua ullamco",
    links: [
      { name: "Pax8", url: "/partnerships/pax8" },
      { name: "ConnectWise", url: "/partnerships/connectwise" },
      { name: "Lenovo", url: "/partnerships/lenovo" },
    ],
    image: PartnershipsOverviewImage,
  },
  {
    id: "about",
    displayName: "About Us",
    description:
      "In laboris dolor consequat culpa ut. Adipisicing cupidatat veniam aliqua ullamco nisi ",
    links: [
      { name: "Leadership", url: "/about/leadership" },
      { name: "Testimonials", url: "/about/testimonials" },
    ],
    image: AboutOverviewImage,
  },
];

const PageData = [
  {
    id: "/services",
    displayName: "Services",
    description:
      "Our expert team specializes in delivering tailored solutions that include strategic planning, system integration, cybersecurity, and cloud services. We are committed to fostering innovation and efficiency, ensuring that your IT infrastructure is robust, secure, and aligned with your business objectives.",
    headerImage: AdobeStock_814768735,
  },
  {
    id: "/services/operational-support",
    displayName: "Operational Support",
    description:
      "We provide essential assistance and maintenance to ensure smooth business operations.",
    headerImage: AdobeStock_717607430,
  },
  {
    id: "/services/cyber-security",
    displayName: "Cyber Security",
    description:
      "We safeguard digital assets and protect against online threats.",
    headerImage: AdobeStock_790571897,
  },
  {
    id: "/services/managed-services",
    displayName: "Managed Services",
    description:
      "Our managed services cover everything from proactive maintenance to troubleshooting, allowing you to focus on your core business.",
    headerImage: AdobeStock_815313473,
  },
  {
    id: "/services/systems-integration",
    displayName: "Systems Integration",
    description:
      "We ensure that disparate software applications and hardware components work harmoniously together. Whether it’s connecting legacy systems, implementing APIs, or streamlining data flow, we facilitate efficient communication across your entire technology ecosystem, enhancing productivity and reducing operational complexities.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/services/solutions-design",
    displayName: "Solutions Design",
    description:
      "Our expertise lies in crafting tailored solutions that precisely align with our clients’ unique business requirements. Whether it’s optimizing processes, enhancing user experiences, or integrating cutting-edge technologies, we ensure that our solutions drive tangible value and propel business growth.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/services/cloud-costs-saas-optimization",
    displayName: "Cloud Costs/SaaS Optimization",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/services/procurement",
    displayName: "Procurement",
    description:
      "We streamline the acquisition process, ensuring efficient sourcing, negotiation, and purchasing of goods and services to meet organizational needs.",
    headerImage: AdobeStock_899201740,
  },
  {
    id: "/services/cost-optimization",
    displayName: "Cost Optimization",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/services/compliance-audit-preparation",
    displayName: "Compliance Audit Preparation",
    description:
      "We assist organizations in preparing for compliance audits by meticulously evaluating existing processes, ensuring alignment with industry standards, and implementing robust controls. We guide clients through the intricacies of compliance requirements, minimizing risks and facilitating a smooth audit process.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/industries",
    displayName: "Industries",
    description:
      "With a strong track record in the financial and healthcare sectors, we understand the unique challenges and compliance requirements these industries face. Our team is equipped to deliver customized solutions that drive efficiency, compliance, and innovation, helping your business stay ahead in a competitive landscape.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/industries/financial",
    displayName: "Financial",
    description:
      "Our IT firm excels in the financial sector, offering innovative solutions that enhance performance and compliance.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/industries/healthcare",
    displayName: "Healthcare",
    description:
      "Et excepteur reprehenderit veniam ex non officia eiusmod ex nostrud. Fugiat cillum veniam enim consectetur eiusmod duis dolor laboris aliqua consectetur velit elit. Dolore exercitation cupidatat ipsum culpa adipisicing in. Excepteur incididunt sint sunt excepteur officia culpa. Aute quis cupidatat.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/industries/aquisitions",
    displayName: "Aquisitions",
    description:
      "In reprehenderit ullamco exercitation sint dolor cupidatat ullamco aliqua incididunt. Amet magna in eiusmod labore eu quis. Quis ad sit est est mollit sunt fugiat reprehenderit ipsum. In nostrud nisi irure ex qui. Nulla esse irure deserunt ipsum ipsum laboris velit laborum cillum veniam ut nulla. Cillum nulla exercitation nulla esse veniam est mollit veniam id. Incididunt laborum quis in ipsum aute adipisicing consequat consequat occaecat culpa ea nulla laboris.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/industries/startups",
    displayName: "Startups",
    description:
      "Ut laboris consequat commodo mollit pariatur id velit sit sint sint. Sit non eu duis pariatur tempor irure ullamco exercitation laborum cupidatat consectetur. Occaecat est id nostrud velit amet duis Lorem elit non ut. Sit occaecat tempor veniam esse fugiat consequat. Aliqua reprehenderit elit excepteur aliqua elit qui elit ea. Laboris quis culpa consectetur cupidatat amet aliqua nostrud labore dolor eiusmod qui aliquip tempor.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/careers",
    displayName: "Careers",
    description:
      "We offer exciting career opportunities in a dynamic environment where creativity, commitment, and collaboration are valued. Explore our open positions and find out how you can contribute to shaping the future of IT solutions with us.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/partnerships",
    displayName: "Partnerships",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/partnerships/pax8",
    displayName: "Pax8",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/partnerships/connectwise",
    displayName: "ConnectWise",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/partnerships/lenovo",
    displayName: "Lenovo",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/about",
    displayName: "About Us",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/about/testimonials",
    displayName: "Testimonials",
    description:
      "From financial giants to healthcare innovators, our partners share how Cyphr Solutions has transformed their IT operations. Their testimonials reflect our commitment to excellence and the trust we’ve built across various industries.",
    headerImage: AdobeStock_762132435,
  },
  {
    id: "/about/leadership",
    displayName: "Leadership",
    description: "Nothing here yet...",
    headerImage: AdobeStock_762132435,
  },
];

export { PageData, NavbarData };
