import { Fragment, useEffect } from "react";

import classes from "./Backdrop.module.css";

const Backdrop = (props) => {
  let { navbarHeight } = props;

  // If we dont add a couple pixels to the navbar height, the bottom
  // border becomes dark
  navbarHeight += 2;

  return (
    <Fragment>
      {props.show && (
        <div
          className={classes.backdrop}
          style={{ height: `calc(100% - ${navbarHeight}px)` }}
          onClick={props.onClose}
          onMouseOver={props.onClose}
        />
      )}
    </Fragment>
  );
};

export default Backdrop;
