import { Container, Col, Row } from "react-bootstrap";

import classes from "./PageHeader.module.css";
import { useEffect } from "react";

const PageHeader = (props) => {
  const { title, description, headerImage } = props;

  let headerImageCSS = {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 100,
    opacity: 0.9,
    textShadow: "2px 2px 4px #000000", // x-offset, y-offset, blur radius, color
  };

  return (
    <div className={classes.header} style={headerImageCSS}>
      <Container>
        <Row className={`${classes.headerRow}`}>
          <Col>
            <h1>{title}</h1>
            <p>{description}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageHeader;
