import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Container, Col, Row } from "react-bootstrap";

import classes from "./Navbar.module.css";

const Navbar = (props) => {
  const [activeTab, setActiveTab] = useState("services");
  const [lastNavbarFocus, setLastNavbarFocus] = useState(false);
  const {
    navbarIconFlipped,
    setNavbarIconFlipped,
    closeBackdropHandler,
    defaultNavbarIconFlipState,
  } = props;
  const { onMouseOver: mouseHoverHandler } = props;

  // Set active navbar tab
  useEffect(() => {
    props.setActiveNavbarTab(activeTab);
  }, [activeTab]);

  const navbarMouseOverHandler = (event) => {
    const navbarFocus = event.target.dataset.title;

    // Set active tab
    setActiveTab(event.target.dataset.title);

    // Set last navbar focus
    setLastNavbarFocus(navbarFocus);

    // Flip navbar icon
    setNavbarIconFlipped({
      ...defaultNavbarIconFlipState,
      [navbarFocus]: true,
    });
  };

  return (
    <div>
      <Col>
        <nav className={classes.navbar} onMouseOver={mouseHoverHandler}>
          <ul>
            <li>
              <Link
                to="/about"
                id="navbar-about"
                onMouseOver={navbarMouseOverHandler}
                // onMouseOut={resetNavbarIconHandler}
                onClick={closeBackdropHandler}
                data-title="about"
              >
                About Us
                <span
                  className={`${classes.navbarIcon} ${
                    navbarIconFlipped.about ? classes.flipped : ""
                  }`}
                >
                  &or;
                </span>
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarMouseOverHandler}
                // onMouseOut={resetNavbarIconHandler}
                id="navbar-careers"
                to="/careers"
                onClick={closeBackdropHandler}
                data-title="careers"
              >
                Careers
                <span
                  className={`${classes.navbarIcon} ${
                    navbarIconFlipped.careers ? classes.flipped : ""
                  }`}
                >
                  &or;
                </span>
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarMouseOverHandler}
                // onMouseOut={resetNavbarIconHandler}
                id="navbar-partnerships"
                to="/partnerships"
                onClick={closeBackdropHandler}
                data-title="partnerships"
              >
                Partnerships
                <span
                  className={`${classes.navbarIcon} ${
                    navbarIconFlipped.partnerships ? classes.flipped : ""
                  }`}
                >
                  &or;
                </span>
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarMouseOverHandler}
                // onMouseOut={resetNavbarIconHandler}
                id="navbar-industries"
                to="/industries"
                onClick={closeBackdropHandler}
                data-title="industries"
              >
                Industries
                <span
                  className={`${classes.navbarIcon} ${
                    navbarIconFlipped.industries ? classes.flipped : ""
                  }`}
                >
                  &or;
                </span>
              </Link>
            </li>
            <li>
              <Link
                onMouseOver={navbarMouseOverHandler}
                // onMouseOut={resetNavbarIconHandler}
                id="navbar-services"
                to="/services"
                onClick={closeBackdropHandler}
                data-title="services"
              >
                Services
                <span
                  className={`${classes.navbarIcon} ${
                    navbarIconFlipped.services ? classes.flipped : ""
                  }`}
                >
                  &or;
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </Col>
    </div>
  );
};

export default Navbar;
