import { useRef } from "react";

import CSSTransition from "react-transition-group/CSSTransition";
import { Container, Row, Col, Button } from "react-bootstrap";

import classes from "./CookieConsent.module.css";
import { Link, useNavigate } from "react-router-dom";

const CookieConsent = (props) => {
  const nodeRef = useRef(null);
  const navigate = useNavigate();

  const leanMoreClickHander = (event) => {
    navigate("/legal/privacy");
  };

  return (
    <Container fluid className={classes.cookie}>
      <CSSTransition
        in={props.show}
        timeout={700}
        nodeRef={nodeRef}
        classNames={{
          enter: classes.fadeEnter,
          enterActive: classes.fadeEnterActive,
          enterDone: classes.fadeEnterDone,
          exit: classes.fadeExit,
          exitActive: classes.fadeExitActive,
        }}
      >
        <Row className="align-items-center justify-content-center">
          <Col>
            <p>
              We use cookies to ensure you get the best experience on our
              website.
            </p>
          </Col>
          <Col>
            <Button variant="success" onClick={props.closeCookieConsent}>
              Accept
            </Button>
            <Button variant="secondary" onClick={leanMoreClickHander}>
              Learn More!
            </Button>
          </Col>
        </Row>
      </CSSTransition>
    </Container>
  );
};

export default CookieConsent;
