import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import PageDetail from "./pages/PageDetail";
import Contact from "./pages/Contact";
import Layout from "./components/Layout/Layout";
import CookieConsent from "./components/CookieConsent/CookieConsent";

import classes from "./App.module.css";

import "./App.css";
import Privacy from "./pages/Legal/Privacy";
import Terms from "./pages/Legal/Terms";

function App() {
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    const result = localStorage.getItem("showCookieConsent");

    if (!result) {
      setShowCookieConsent(true);
    }
  }, []);

  const closeCookieConsent = () => {
    setShowCookieConsent(false);
    localStorage.setItem("showCookieConsent", false);
  };

  return (
    <div className="App">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/services/*"
            element={<PageDetail pageId={currentLocation.pathname} />}
          />
          <Route
            path="/industries/*"
            element={<PageDetail pageId={currentLocation.pathname} />}
          />
          <Route
            path="/careers/*"
            element={<PageDetail pageId={currentLocation.pathname} />}
          />
          <Route
            path="/partnerships/*"
            element={<PageDetail pageId={currentLocation.pathname} />}
          />
          <Route
            path="/about/*"
            element={<PageDetail pageId={currentLocation.pathname} />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/legal/privacy" element={<Privacy />} />
          <Route path="/legal/terms" element={<Terms />} />
        </Routes>
      </Layout>

      {showCookieConsent && (
        <CookieConsent show={true} closeCookieConsent={closeCookieConsent} />
      )}
    </div>
  );
}

export default App;
