import { Fragment } from "react";

import SiteHeader from "../SiteHeader/SiteHeader";
import SiteFooter from "../SiteFooter/SiteFooter";

const Layout = (props) => {
  return (
    <Fragment>
      <SiteHeader />
      <main>{props.children}</main>
      <SiteFooter />
    </Fragment>
  );
};

export default Layout;
