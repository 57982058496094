import { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { Container, Row, Col } from "react-bootstrap";
import CSSTransition from "react-transition-group/CSSTransition";

import classes from "./Contact.module.css";
import ContactForm from "../components/Form/ContactForm";
import PageHeader from "../components/PageHeader/PageHeader";

import AdobeStock_1028509816 from "../assets/AdobeStock_1028509816_Preview.jpeg";

const Contact = (props) => {
  const [isShowing, setIsShowing] = useState(false);
  const leftColumnNodeRef = useRef(null);
  const rightColumnNodeRef = useRef(null);

  useEffect(() => {
    if (isShowing == true) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsShowing(true);

      return () => {
        clearTimeout(timeout);
      };
    }, 500);

    console.log(isShowing);
  }, [isShowing]);

  const leftColumnClasses = [classes.column, classes.columnLeft];
  const rightColumnClasses = [classes.column, classes.columnRight];

  return (
    <div className={classes.contact}>
      <PageHeader
        title="Contact Us"
        description="Reach Out to Our IT Professionals: We’re dedicated to providing reliable managed services and comprehensive support for all your IT needs. Contact us today!"
        headerImage={AdobeStock_1028509816}
      />
      <Container>
        <Row className={classes.row}>
          <CSSTransition
            in={isShowing}
            classNames={{
              enter: classes.enterLeft,
              enterActive: classes.enterLeftActive,
              enterDone: classes.enterLeftDone,
            }}
            nodeRef={leftColumnNodeRef}
            timeout={700}
          >
            <Col
              className={leftColumnClasses.join(" ")}
              ref={leftColumnNodeRef}
            >
              <div className="text-muted">
                <h3>Email</h3>
                <p>
                  <a
                    className="text-muted"
                    href="mailto:info@cyphrsolutions.com"
                  >
                    info@cyphrsolutions.com
                  </a>
                </p>

                <h3>Phone</h3>
                <p>
                  <a className="text-muted" href="tel:+12126451010">
                    +1 212 645 1010
                  </a>
                </p>

                <h3>Mailing Address</h3>
                <p>
                  <span className={classes.companyName}>
                    Cyphr Solutions Inc.
                  </span>
                  <br />
                  17633 Gunn Hwy.
                  <br />
                  Suite 325 <br />
                  Odessa, FL 33556
                  <br />
                  United States
                </p>
              </div>
            </Col>
          </CSSTransition>

          <CSSTransition
            in={isShowing}
            classNames={{
              enter: classes.enterRight,
              enterActive: classes.enterRightActive,
              enterDone: classes.enterRightDone,
            }}
            nodeRef={rightColumnNodeRef}
            timeout={700}
          >
            <Col
              className={rightColumnClasses.join(" ")}
              ref={rightColumnNodeRef}
            >
              <ContactForm />
            </Col>
          </CSSTransition>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
