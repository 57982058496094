import { Fragment } from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import classes from "./Legal.module.css";

const Terms = (props) => {
  return (
    <Container className={classes.legal}>
      <Row>
        <Col>
          <div className={classes.header}>
            <h2>Terms of Use</h2>
            <p>Last updated: June 12th, 2024</p>
          </div>

          <ol>
            <li className={classes["list-header"]}>
              <h3>Introduction</h3>
              <ol>
                <li>
                  These terms and conditions shall govern your use of our
                  website.
                </li>
                <li>
                  By using our website, you accept these terms and conditions in
                  full; accordingly, if you disagree with these terms and
                  conditions or any part of these terms and conditions, you must
                  not use our website.
                </li>
                <li>
                  If you register with our website, submit any material to our
                  website or use any of our website services, we will ask you to
                  expressly agree to these terms and conditions.
                </li>
                <li>
                  Our website uses cookies; by using our website or agreeing to
                  these terms and conditions, you consent to our use of cookies
                  in accordance with the terms of our{" "}
                  <Link to="/legal/privacy">privacy and cookies policy</Link>.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Copyright notice</h3>
              <ol>
                <li>Copyright © 2024 Cyphr Solutions Inc.</li>
                <li>
                  Subject to the express provisions of these terms and
                  conditions:
                </li>
                <ul>
                  <li>
                    (a) we, together with our licensors, own and control all the
                    copyright and other intellectual property rights in our
                    website and the material on our website; and
                  </li>
                  <li>
                    (b) all the copyright and other intellectual property rights
                    in our website and the material on our website are reserved.
                  </li>
                </ul>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Licence to use website</h3>
              <ol>
                <li>You may:</li>
                <ul>
                  <li>(a) view pages from our website in a web browser;</li>
                  <li>
                    (b) download pages from our website for caching in a web
                    browser;
                  </li>
                  <li>
                    (c) stream audio and video files from our website; and
                  </li>
                  <li>
                    (d) use our website services by means of a web browser,
                    subject to the other provisions of these terms and
                    conditions.{" "}
                  </li>
                </ul>
                <li>
                  Except as expressly permitted by Section 3.1 or the other
                  provisions of these terms and conditions, you must not
                  download any material from our website or save any such
                  material to your computer.
                </li>
                <li>
                  Except as expressly permitted by these terms and conditions,
                  you must not edit or otherwise modify any material on our
                  website.
                </li>
                <li>
                  Unless you own or control the relevant rights in the material,
                  you must not:
                </li>
                <ul>
                  <li>
                    (a) republish material from our website (including
                    republication on another website);
                  </li>
                  <li>
                    (b) sell, rent or sub-license material from our website;
                  </li>
                  <li>(c) show any material from our website in public;</li>
                  <li>
                    (d) exploit material from our website for a commercial
                    purpose; or
                  </li>
                  <li>(e) redistribute material from our website.</li>
                </ul>
                <li>
                  We reserve the right to restrict access to areas of our
                  website, or indeed our whole website, at our discretion; you
                  must not circumvent or bypass, or attempt to circumvent or
                  bypass, any access restriction measures on our website.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Acceptable use</h3>
              <ol>
                <li>You must not:</li>
                <ul>
                  <li>
                    (a) use our website in any way or take any action that
                    causes, or may cause, damage to the website or impairment of
                    the performance, availability or accessibility of the
                    website;
                  </li>
                  <li>
                    (b) use our website in any way that is unlawful, illegal,
                    fraudulent or harmful, or in connection with any unlawful,
                    illegal, fraudulent or harmful purpose or activity;
                  </li>
                  <li>
                    (c) use our website to copy, store, host, transmit, send,
                    use, publish or distribute any material which consists of
                    (or is linked to) any spyware, computer virus, Trojan horse,
                    worm, keystroke logger, rootkit or other malicious computer
                    software;
                  </li>
                  <li>
                    (d) conduct any systematic or automated data collection
                    activities (including without limitation scraping, data
                    mining, data extraction and data harvesting) on or in
                    relation to our website without our express written consent;
                  </li>
                  <li>
                    (e) access or otherwise interact with our website using any
                    robot, spider or other automated means, except for the
                    purpose of search engine indexing;
                  </li>
                  <li>
                    (f) violate the directives set out in the robots.txt file
                    for our website; or
                  </li>
                  <li>
                    (g) use data collected from our website for any direct
                    marketing activity (including without limitation email
                    marketing, SMS marketing, telemarketing and direct mailing).
                  </li>
                </ul>
                <li>
                  You must not use data collected from our website to contact
                  individuals, companies or other persons or entities.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Exclusion of Liability</h3>
              <ol>
                <li>
                  THE INFORMATION PROVIDED ON THIS WEBSITE IS BELIEVED TO BE
                  RELIABLE WHEN POSTED. QUBIT SOLUTIONS CANNOT GUARANTEE THAT
                  INFORMATION WILL BE ACCURATE, COMPLETE AND CURRENT AT ALL
                  TIMES. IN NO EVENT WILL QUBIT SOLUTIONS LIMITED BE LIABLE TO
                  ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL OR OTHER
                  CONSEQUENTIAL DAMAGE SUSTAINED BY ANY USER AS A RESULT OF
                  HIS/HER RELIANCE ON INFORMATION CONTAINED IN THIS WEBSITE
                  AND/OR USE OF THIS WEB SITE AND/OR USE OF ANY OTHER LINKED
                  SITE, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS,
                  BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR OTHER DATA ON YOUR
                  INFORMATION HANDLING SYSTEM OR OTHERWISE.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Breaches of these terms and conditions</h3>
              <ol>
                <li>
                  {" "}
                  Without prejudice to our other rights under these terms and
                  conditions, if you breach these terms and conditions in any
                  way, or if we reasonably suspect that you have breached these
                  terms and conditions in any way, we may:
                </li>
                <ul>
                  <li>(a) send you one or more formal warnings;</li>
                  <li>(b) temporarily suspend your access to our website;</li>
                  <li>
                    (c) permanently prohibit you from accessing our website;
                  </li>
                  <li>
                    (d) block computers using your IP address from accessing our
                    website;
                  </li>
                  <li>
                    (e) contact any or all of your internet service providers
                    and request that they block your access to our website;
                  </li>
                  <li>
                    (f) commence legal action against you, whether for breach of
                    contract or otherwise; and/or
                  </li>
                </ul>
                <li>
                  Where we suspend or prohibit or block your access to our
                  website or a part of our website, you must not take any action
                  to circumvent such suspension or prohibition or blocking
                  (including without limitation creating and/or using a
                  different account).
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Variation</h3>
              <ol>
                <li>
                  We may revise these terms and conditions from time to time.
                </li>
                <li>
                  The revised terms and conditions shall apply to the use of our
                  website from the date of publication of the revised terms and
                  conditions on the website, and you hereby waive any right you
                  may otherwise have to be notified of, or to consent to,
                  revisions of these terms and conditions.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Assignment</h3>
              <ol>
                <li>
                  You hereby agree that we may assign, transfer, sub-contract or
                  otherwise deal with our rights and/or obligations under these
                  terms and conditions.
                </li>
                <li>
                  You may not without our prior written consent assign,
                  transfer, sub-contract or otherwise deal with any of your
                  rights and/or obligations under these terms and conditions.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Severability</h3>
              <ol>
                <li>
                  If a provision of these terms and conditions is determined by
                  any court or other competent authority to be unlawful and/or
                  unenforceable, the other provisions will continue in effect.
                </li>
                <li>
                  If any unlawful and/or unenforceable provision of these terms
                  and conditions would be lawful or enforceable if part of it
                  were deleted, that part will be deemed to be deleted, and the
                  rest of the provision will continue in effect.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Third party rights</h3>
              <ol>
                <li>
                  A contract under these terms and conditions is for our benefit
                  and your benefit, and is not intended to benefit or be
                  enforceable by any third party.
                </li>
                <li>
                  The exercise of the parties' rights under a contract under
                  these terms and conditions is not subject to the consent of
                  any third party.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Entire agreement</h3>
              <ol>
                <li>
                  Subject to Section 11.1, these terms and conditions, together
                  with our privacy and cookies policy, shall constitute the
                  entire agreement between you and us in relation to your use of
                  our website and shall supersede all previous agreements
                  between you and us in relation to your use of our website.
                </li>
              </ol>
            </li>
            <li className={classes["list-header"]}>
              <h3>Law and jurisdiction</h3>
              <ol>
                <li>
                  The use of this Website shall be governed by the Law of The
                  United States of America.
                </li>
                <li>
                  Any disputes relating to these terms and conditions shall be
                  subject to the exclusive jurisdiction of the courts of The
                  United States of America.
                </li>
              </ol>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
